<template>
  <VLink
    class="flex items-center p-2 hover:bg-gray-100"
    :to="{
      name: 'biz-products-slug',
      params: { slug },
    }"
  >
    <div class="w-12">
      <StoreProductMedia :value="media" size="200x200" ratio />
    </div>
    <div class="ms-2">
      <p class="text-sm">{{ name }}</p>
      <p class="text-xs text-gray-500">
        {{ categories.map((cat) => cat.name).join(",") }}
      </p>
    </div>
  </VLink>
</template>

<script>
export default {
  props: {
    name: { type: String, default: null },
    media: { type: Array, default: null },
    slug: { type: String, default: null },
    categories: { type: Array, default: null },
  },
};
</script>
