<template>
  <article class="p-2 flex items-center">
    <div class="flex animate-pulse">
      <div class="h-12 w-12 bg-shimmer rounded-md dark:bg-gray-500"></div>
    </div>
    <div class="flex-grow ms-2">
      <div class="h-5 bg-shimmer rounded w-3/4 dark:bg-gray-500"></div>
      <div class="h-2 bg-shimmer rounded mt-1 w-12 dark:bg-gray-500"></div>
    </div>
  </article>
</template>
