<template>
  <component
    :is="route ? vLink : 'button'"
    :to="route"
    class="h-12 w-12 p-3 text-gray-500 flex-shrink-0"
  >
    <Icon :name="icon" class="w-full h-full" />
  </component>
</template>

<script>
import { resolveComponent } from "vue";

export default {
  setup() {
    const vLink = resolveComponent("VLink");
    return { vLink };
  },
  props: {
    icon: { type: String, default: null },
    route: { type: Object, default: null },
  },
};
</script>
