<template>
  <div
    class="w-full h-[--headerHeight] relative flex flex-col items-center justify-center py-2 text-gray-500 lg:py-0 nav-item dark:bg-gray-900 hover:text-primary-500"
  >
    <Icon class="w-6 h-6" :name="icon" />
    <p class="px-2 text-sm">{{ label }}</p>
    <div
      v-if="count"
      class="nav-count absolute top-2.5 bg-danger-700 text-white rounded-full w-5 h-5 text-xxs flex justify-center items-center z-20"
    >
      {{ count }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: "Label" },
    icon: { type: String, default: "Icon" },
    count: { type: Number, default: 0 },
  },
};
</script>

<style>
.nav-count {
  margin-inline-start: 12px;
  left: 50%;
  &:after {
    content: "";
    z-index: -1;
    @apply inset-0 absolute animate-ping bg-danger-700 rounded-full;
  }
}
</style>
