<template>
  <div
    v-click-outside="
      () => {
        $emit('close');
      }
    "
    class="max-w-xs search"
    :class="{ 'search--open': open }"
  >
    <div class="relative w-full mx-auto md:max-w-lg">
      <!-- Header -->
      <div class="search__header">
        <div class="search__bar">
          <FormIconInput
            v-model="search"
            class="dark:!text-gray-500 dark:!bg-gray-800 dark:!rounded"
            type="text"
            :placeholder="$t('search_items')"
            icon="IcRoundSearch"
            @input="$emit('checkVal', $event)"
            @enter-pressed="goToSearch"
            @click="goToSearch"
          />
        </div>
        <StoreHeaderButton
          class="ms-2 md:hidden"
          icon="MdiClose"
          @click="closeSearch"
        />
      </div>
      <!-- Content -->
      <div v-if="open" class="search__content">
        <Query :query="searchProductsGQL" :variables="{ hash, search }">
          <template #loading>
            <ShimmerSearchProduct v-for="n in 5" :key="`search-shimmer-${n}`" />
          </template>
          <template
            #default="{
              data: {
                products: { data },
              },
            }"
          >
            <div v-if="data.length > 0" class="divide-y">
              <StoreSearchProduct
                v-for="item in data"
                :key="`search-product-${item.id}`"
                :name="item.name"
                :media="item.media"
                :slug="item.slug"
                :categories="item.category"
              />
              <div class="flex items-center justify-center p-3">
                <VyButton
                  size="md"
                  color="primary"
                  shape="rounded"
                  theme="muted"
                  icon="MdiArrowRight"
                  direction="rowReverse"
                  :label="$t('view_all')"
                  @click="goToSearch"
                />
              </div>
            </div>
            <div v-else class="flex flex-col p-4">
              <p>{{ $t("no_result_title") }}</p>
              <p class="text-xs text-gray-500">{{ $t("no_result_desc") }}</p>
            </div>
          </template>
        </Query>
      </div>
    </div>
  </div>
</template>

<script>
import searchProducts from "@/graph/store/search-product.gql";
import { useBizStore } from "@/store/index.js";
import { useVepaarStore } from "@/store/store.js";
import vClickOutside from "click-outside-vue3";
import { mapState } from "pinia";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    open: Boolean,
  },

  searchProducts,

  emits: ["checkVal", "close"],

  data() {
    return {
      searchProductsGQL: searchProducts,
      search: null,
    };
  },
  computed: {
    ...mapState(useVepaarStore, ["hash"]),
    ...mapState(useBizStore, ["subdomain"]),
  },

  watch: {
    $route(route) {
      if (!route.query.search) {
        this.closeSearch();
      }
    },
  },

  methods: {
    goToSearch() {
      this.$emit("close");
      if (this.search) {
        this.$router.push({
          name: "biz-products",
          query: {
            search: this.search,
          },
          params: {
            biz: this.subdomain,
          },
        });
      } else {
        this.$router.push({
          name: "biz",
          params: {
            biz: this.subdomain,
          },
        });
      }
    },

    closeSearch() {
      this.search = null;
      this.$emit("close");
    },
  },
};
</script>

<style>
.search {
  min-width: 180px;
  @apply flex-grow hidden items-center justify-end md:justify-center md:mr-4 md:ml-auto md:flex;
}

.search--open {
  @apply fixed inset-0 bg-white z-10 block dark:bg-gray-900;
  @apply md:static;
}

.search__header {
  @apply h-[--headerHeight] flex items-center w-full justify-center border-b px-4;
  @apply md:border-none md:p-0;
}

.search__bar {
  @apply relative h-10 md:block w-full;

  input {
    @apply absolute inset-0 bg-gray-100 w-full h-full rounded-md px-4 outline-none;
    @apply focus:ring-2 focus:ring-primary-100 focus:ring-inset;
  }
}

.search__content {
  @apply bg-white z-20 overflow-hidden dark:bg-gray-900;
  @apply md:absolute md:left-0 md:right-0 md:top-14 md:shadow-2xl md:rounded-lg md:border;
}
</style>
