<template>
  <div class="icon-input">
    <input
      v-bind="$attrs"
      :value="modelValue"
      @input="$emit('update:model-value', $event.target.value)"
      @keyup.enter="$emit('enter-pressed')"
    />
    <VyButton
      size="md"
      color="gray"
      shape="square"
      :icon="icon"
      class="flex-grow-0 text-gray-500"
      @click="$emit('click')"
    />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    search: {
      type: String,
      default: "",
    },
  },
  emits: ["update:model-value", "click", "enter-pressed"],
};
</script>

<style>
.icon-input {
  @apply h-full flex items-center;

  & input {
    @apply pe-10;
  }

  & button {
    @apply absolute rounded-e dark:bg-gray-800;
    right: 2px;
    width: 2.25rem;
  }
}
</style>
