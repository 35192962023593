<template>
  <VLink :to="{ name: 'biz' }" class="flex items-center min-w-0 header__logo">
    <img v-if="logo" class="h-12 me-2" :src="logo" :alt="name" />
    <h1
      class="overflow-hidden text-lg font-bold max-w-40 sm:max-w-80 font-display whitespace-nowrap text-ellipsis"
    >
      {{ name }}
    </h1>
  </VLink>
</template>

<script>
import { useBizStore } from "@/store/index.js";
import { mapState } from "pinia";

export default {
  data() {
    return {
      biz: null,
    };
  },
  computed: {
    ...mapState(useBizStore, ["logo", "name"]),
  },
};
</script>
