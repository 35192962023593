<template>
  <component
    :is="route ? vLink : 'div'"
    :to="{ name: route }"
    class="cart-step"
    :class="{ 'cart-step--active': active }"
  >
    <p>{{ no }}</p>
    <span>{{ label }}</span>
  </component>
</template>

<script>
export default {
  props: {
    label: { type: String, default: null },
    route: { type: String, default: null },
    no: { type: Number, default: null },
    active: Boolean,
  },
  setup() {
    const vLink = resolveComponent("VLink");
    return { vLink };
  },
};
</script>

<style>
.cart-step {
  @apply text-center flex flex-col justify-center items-center relative;

  p {
    @apply w-8 h-8 flex rounded-full items-center justify-center font-bold bg-gray-100 text-sm text-gray-400 relative z-10 dark:bg-gray-800 dark:text-gray-500;
  }

  span {
    @apply mt-1 uppercase text-gray-500 text-xxs tracking-widest font-medium;
  }

  &:after,
  &:before {
    content: "";
    top: calc(50% - 10px);
    @apply absolute h-0.5 bg-gray-200 w-1/2 dark:bg-gray-600;
  }

  &:before {
    @apply start-0;
  }

  &:after {
    @apply end-0;
  }

  &:first-child {
    &::before {
      @apply hidden;
    }
  }

  &:last-child {
    &::after {
      @apply hidden;
    }
  }
}

.cart-step--active {
  p {
    @apply bg-primary-500 text-primary-50 dark:bg-primary-400 dark:text-primary-900;
  }

  span {
    @apply text-primary-500 dark:text-primary-400;
  }

  &:before,
  &:after {
    @apply bg-primary-500 dark:bg-primary-400;
  }

  &.router-link-exact-active {
    p {
      @apply bg-white text-primary-500 border-2 border-primary-500 dark:text-primary-400 dark:bg-primary-400 dark:text-gray-900 dark:border-primary-400;
    }
  }
}
</style>
