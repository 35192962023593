<template>
  <nav
    class="fixed bottom-0 left-0 right-0 z-20 grid grid-cols-4 ml-auto bg-white border-t nav lg:static lg:border-none lg:grid-cols-5 dark:bg-gray-900 dark:text-gray-500"
  >
    <!-- HOME -->
    <VLink class="lg:hidden" :to="{ name: 'biz' }">
      <StoreNavItem :label="$t('home')" icon="IcTwotoneHome" class="is-root" />
    </VLink>

    <!-- DARK MODE -->
    <div class="hidden lg:block">
      <ClientOnly>
        <DarkMode v-slot="{ toggle, label, icon }">
          <StoreNavItem
            class="capitalize cursor-pointer"
            :label="label"
            :icon="icon"
            @click="toggle()"
          />
        </DarkMode>
      </ClientOnly>
    </div>

    <!-- INFO -->
    <VLink class="hidden lg:flex" :to="{ name: 'biz-about' }">
      <StoreNavItem :label="$t('info')" icon="IcTwotoneInfo" />
    </VLink>

    <!-- CATEGORIES -->
    <VLink :to="{ name: 'biz-categories' }">
      <StoreNavItem :label="$t('categories')" icon="IcTwotoneCategory" />
    </VLink>

    <!-- CART -->
    <VLink :to="{ name: 'biz-cart' }">
      <StoreNavItem
        :label="$t('cart')"
        icon="IcTwotoneShoppingCart"
        :count="productCountInCart"
      />
    </VLink>

    <!-- LOGGED IN -->
    <template v-if="isLoggedIn">
      <VLink class="menu-account lg:hidden" :to="{ name: 'biz-account' }">
        <StoreNavItem :label="$t('account')" icon="IcTwotoneAccountCircle" />
      </VLink>

      <VLink
        class="hidden menu-account lg:flex"
        :to="{ name: 'biz-account-profile' }"
      >
        <StoreNavItem :label="$t('account')" icon="IcTwotoneAccountCircle" />
      </VLink>
    </template>

    <!-- GUEST -->
    <template v-else>
      <VLink :to="{ name: 'biz-login' }">
        <StoreNavItem :label="$t('login')" icon="IcTwotoneAccountCircle" />
      </VLink>
    </template>
  </nav>
</template>

<script>
import { useCartStore } from "@/store/cart.js";
import { storeToRefs, mapState } from "pinia";
import { useVepaarStore } from "@/store/store.js";
import { useAuthStore } from "@/store/auth.js";

export default {
  async setup() {
    const cartStore = useCartStore();
    const vepaarStore = useVepaarStore();

    const { productCount: productCountInCart } = storeToRefs(cartStore);

    return {
      productCountInCart,
      cartStore,
      vepaarStore,
    };
  },
  computed: {
    ...mapState(useAuthStore, ["isLoggedIn"]),
  },
};
</script>

<style>
.nav {
  .router-link-active:not(.is-root),
  .router-link-exact-active {
    .nav-item {
      @apply text-primary-500 relative dark:text-primary-400 dark:hover:text-primary-400;

      &:after {
        content: "";
        @apply absolute bottom-0 left-0 right-0 h-1 bg-primary-500 lg:h-0.5 lg:-bottom-px;
      }

      .nav-count {
        &:after {
          display: none;
        }
      }
    }
  }
}
</style>
