<template>
  <div>
    <slot
      :toggle="toggleDarkMode"
      :label="vepaarStore.mode"
      :icon="vepaarStore.mode == 'light' ? 'IcLightMode' : 'IcDarkMode'"
    >
      <button
        class="items-center cursor-pointer group nav-item hover:text-primary-600 dark:hover:text-primary-400"
        @click="toggleDarkMode"
      >
        <Icon
          class="w-6 h-6 text-gray-600 group-hover:text-primary-600 dark:group-hover:text-primary-400"
          :name="vepaarStore.mode == 'light' ? 'IcLightMode' : 'IcDarkMode'"
        />
        <p v-if="!isMobile" class="text-sm capitalize">
          {{ vepaarStore.mode }}
        </p>
      </button>
    </slot>
  </div>
</template>

<script>
import { useVepaarStore } from "@/store/store.js";

export default {
  props: {
    isMobile: Boolean,
  },
  setup() {
    onMounted(() => {
      // set theme if already existed
      const mode = localStorage.getItem(vepaarStore.hash + "_" + "mode");
      vepaarStore.mode = mode ? mode : "light";
      document.documentElement.classList.toggle(
        "dark",
        vepaarStore.mode === "dark"
      );
    });

    const colorMode = useColorMode();
    const vepaarStore = useVepaarStore();

    // on toggle mode
    const toggleDarkMode = () => {
      colorMode.preference = colorMode.preference == "light" ? "dark" : "light";
      vepaarStore.mode = colorMode.preference;
      localStorage.setItem(vepaarStore.hash + "_" + "mode", vepaarStore.mode);
      document.documentElement.classList.toggle(
        "dark",
        vepaarStore.mode === "dark"
      );
    };

    return {
      colorMode,
      toggleDarkMode,
      vepaarStore,
    };
  },
};
</script>
