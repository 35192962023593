<template>
  <header
    class="z-20 header lg:z-30 dark:bg-gray-900 dark:text-gray-300"
    :class="`header--${type}`"
  >
    <div class="container">
      <div class="header__container">
        <div class="header__top">
          <Logo class="me-2" />

          <template v-if="type == 'cart'">
            <div class="header__steps">
              <StoreCartStep
                v-for="(step, index) in steps"
                :key="`step-${index}`"
                :active="step.active"
                :route="step.active ? step.route : null"
                :no="index + 1"
                :label="step.label"
              />
            </div>
          </template>

          <template v-else>
            <StoreSearch
              :open="search"
              @check-val="checkVal"
              @close="search = false"
            />

            <StoreHeaderButton
              class="ms-auto lg:hidden"
              icon="MdiInformationOutline"
              :route="{ name: 'biz-about' }"
            />

            <StoreHeaderButton
              class="md:hidden"
              icon="IcRoundSearch"
              @click="toggleSearch(true)"
            />

            <ClientOnly>
              <DarkMode v-slot="{ toggle, icon }">
                <StoreHeaderButton
                  class="cursor-pointer lg:hidden"
                  :icon="icon"
                  @click="toggle()"
                />
              </DarkMode>
            </ClientOnly>

            <StoreHeaderButton
              class="lg:hidden"
              icon="MdiMenu"
              @click="$bus.$emit('openHeaderMenu')"
            />
          </template>
        </div>

        <StoreNav />
      </div>
    </div>
  </header>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "store",
    },
  },

  setup() {
    const route = useRoute();
    return { route };
  },

  data() {
    return {
      search: false,
    };
  },

  computed: {
    steps() {
      return [
        {
          route: "biz-cart",
          active: true,
          label: this.$t("cart"),
          enabled: true,
        },
        {
          route: "biz-cart-address",
          active: [
            "biz-cart-address",
            "biz-cart-payment",
            "biz-cart-whatsapp",
            "cart-address",
            "cart-payment",
            "cart-whatsapp",
          ].includes(this.route.name),

          enabled: true,
          label: this.$t("address"),
        },
        {
          route: "biz-cart-payment",
          active: [
            "biz-cart-payment",
            "biz-cart-whatsapp",
            "cart-payment",
            "cart-whatsapp",
          ].includes(this.route.name),
          label: this.$t("payment"),
          enabled: true,
        },
      ].filter((step) => step.enabled);
    },
  },

  methods: {
    checkVal(event) {
      if (event) {
        this.search = true;
      } else {
        this.search = false;
      }
    },
    toggleSearch(value) {
      this.search = value;
    },
  },
};
</script>

<style>
.header {
  @apply bg-white border-b sticky top-0 flex items-center ps-4 h-[--headerHeight] lg:h-auto;
}

.header__container {
  @apply flex items-center;
}

.header__top {
  @apply flex items-center flex-grow;
}

.header__nav {
  @apply fixed z-20 ms-auto bottom-0 left-0 right-0 border-t bg-white;
  @apply lg:static lg:border-none;
}

.header__steps {
  @apply flex-grow grid max-w-xl mx-auto grid-cols-3;
}

.header--cart {
  @apply bg-white;

  .header__logo {
    @apply hidden lg:flex;
  }
}
</style>
